import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Buefy from 'buefy'

import 'buefy/dist/buefy.css'
import './assets/sass/styles.scss'
import '@mdi/font/css/materialdesignicons.min.css'

Vue.config.productionTip = false

Vue.use(Buefy, {
  defaultIconPack: 'mdi'
})

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
