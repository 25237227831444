<template>
  <form class="box" @submit.prevent="sendRegistrationMemberInfo">
    <h1 class="title has-text-centered">
      Регистрация в Детский Клуб «Лето побед-2024»
    </h1>
    <div class="content">
      <p>
        Участие в Клубе для обучающихся образовательных организаций,
        подведомственных Департаменту образования и науки города Москвы,
        бесплатное. Один ребенок может посетить не более двух смен. В первую
        очередь участниками Клуба станут дети из семей льготных категорий:
        многодетных, малообеспеченных, дети военных  по мобилизации, дети-сироты и
        оставшиеся без попечения родителей.
      </p>
      <p>Все документы оформляются на данной странице проекта.</p>
      <p>
        Ребенок допускается в Клуб только при наличии справки об отсутствии
        контактов с инфекционными больными (эпидокружении), которая
        действительна в течение 3-х суток. Справка передается в Клуб в первый
        день смены.
      </p>
    </div>
    <b-button
      icon-left="file-download"
      type="is-link is-light"
      tag="a"
      href="/documents/Памятка Лето побед 2024 v2.pdf"
      target="_blank"
    >
      Скачать памятка для родителей
    </b-button>
    <br />
    <br />
    <h4 class="title is-size-4">Выбор смены</h4>
    <div class="columns">
      <div class="column">
        <b-field label="Смена">
          <b-select
            expanded
            placeholder="Выберите смену"
            v-model="memberInfo.selectedShift"
          >
            <option
              v-for="option in shifts"
              :value="option.id"
              :class="{ 'is-disabled': !option.visible }"
              :disabled="!option.visible"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Направление">
          <b-select
            expanded
            :disabled="disabledDirections"
            placeholder="Выберите направление"
            v-model="memberInfo.selectedDirection"
          >
            <option
              v-for="option in selectDirections"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Возраст">
          <b-select
            expanded
            :disabled="disabledAgeGroup"
            placeholder="Выберите возрастную группу"
            v-model="memberInfo.selectedAgeGroup"
          >
            <option
              v-for="option in selectAgeGroups"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <p v-if="shiftMessage" class="has-text-centered" :class="{ 'has-text-danger': shiftMessageType }">{{ shiftMessage }}</p>
    <hr />
    <h4 class="title is-size-4">Информация о ребенке</h4>
    <div class="columns">
      <div class="column">
        <b-field label="Фамилия ребенка">
          <b-input
            required
            v-model="memberInfo.firstname"
            placeholder="Введите фамилия"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Имя ребенка">
          <b-input
            required
            v-model="memberInfo.surname"
            placeholder="Введите имя"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Отчество ребенка">
          <b-input
            v-model="memberInfo.lastname"
            placeholder="Введите отчество"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Полных лет">
          <b-select
            expanded
            required
            placeholder="Выберите возраст"
            v-model="memberInfo.age"
          >
            <option
              v-for="option in ages"
              :value="option.id"
              :key="option.id"
            >
              {{ option.id }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Образовательная организация">
          <b-input
            v-model="memberInfo.school"
            required
            placeholder="Введите образовательную организацию"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Фактический адрес проживания">
          <b-input
            v-model="memberInfo.address"
            required
            placeholder="Введите фактический адрес проживания"
          ></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="ФИО родителя/законного представителя ">
          <b-input
            v-model="memberInfo.parentFullName"
            required
            placeholder="Введите ФИО"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Телефон родителя/законного представителя">
          <b-input
            v-model="memberInfo.phoneNumber"
            required
            placeholder="+7__________"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Резервный телефон" class="fix-input-label">
          <b-input
            v-model="memberInfo.reservePhoneNumber"
            required
            placeholder="+7__________"
          ></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column is-half">
        <b-field label="Электронная почта родителя/законного представителя">
          <b-input
            v-model="memberInfo.email"
            required
            type="email"
            placeholder="email@gmail.com"
          ></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column is-one-third">
        <b-field label="Статус семьи">
          <b-select
            expanded
            required
            placeholder="Выберите статус семьи из списка"
            v-model="memberInfo.selectedFamilyStatus"
          >
            <option
              v-for="option in familyStatuses"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field
          label="Документ, подтверждающий статус семьи/ребенка"
          type="is-danger"
          :message="familyStatusFileMessage"
        >
          <b-upload
            expanded
            :disabled="parseInt(memberInfo.selectedFamilyStatus) === 0"
            v-model="memberInfo.familyStatusFile"
            class="file-label is-align-items-center file-panel"
            drag-drop
          >
            <div class="columns">
              <div
                class="column has-text-centered is-flex is-align-items-center is-justify-content-center pr-0 file-panel-icon"
              >
                <b-icon icon="upload" size="is-medium"> </b-icon>
              </div>
              <div
                class="column is-four-fifths pr-5 is-flex is-align-items-center has-text-centered is-justify-content-center"
              >
                {{
                  memberInfo.familyStatusFile
                    ? "Прикреплено: " + memberInfo.familyStatusFile.name
                    : "Прикрепить скан/фотографию"
                }}
              </div>
            </div>
          </b-upload>
        </b-field>
      </div>
    </div>

    <hr />
    <h4 class="title is-size-4">Документы</h4>
    <h6 class="title is-size-6">
      При наличии электронной подписи подписать электронно, при отсутствии –
      скачать документ, подписать бумажный вариант,
      отсканировать/сфотографировать и прикрепить)
    </h6>

    <div class="columns">
      <div class="column is-one-third">
        <b-field
          label="Паспорт родителя: первая страница и прописка"
          type="is-danger"
          :message="passportFileMessage"
        >
          <b-upload
            expanded
            v-model="memberInfo.passportFile"
            class="file-label is-align-items-center file-panel"
            drag-drop
          >
            <div class="columns">
              <div
                class="column has-text-centered is-flex is-align-items-center is-justify-content-center pr-0 file-panel-icon"
              >
                <b-icon icon="upload" size="is-medium"> </b-icon>
              </div>
              <div
                class="column is-four-fifths pr-5 is-flex is-align-items-center has-text-centered is-justify-content-center"
              >
                {{
                  sliceFileUploadMessage(
                    memberInfo.passportFile
                      ? "Прикреплено: " + memberInfo.passportFile.name
                      : "Прикрепить скан/фотографию"
                  )
                }}
              </div>
            </div>
          </b-upload>
        </b-field>
      </div>
      <div class="column is-one-third">
        <b-field
          label="Свидетельство о рождении ребенка"
          type="is-danger"
          :message="birthCertificateFileMessage"
          class="fix-input-label"
        >
          <b-upload
            expanded
            v-model="memberInfo.birthCertificateFile"
            class="file-label is-align-items-center file-panel"
            drag-drop
          >
            <div class="columns">
              <div
                class="column has-text-centered is-flex is-align-items-center is-justify-content-center pr-0 file-panel-icon"
              >
                <b-icon icon="upload" size="is-medium"> </b-icon>
              </div>
              <div
                class="column is-four-fifths pr-5 is-flex is-align-items-center has-text-centered is-justify-content-center"
              >
                {{
                  sliceFileUploadMessage(
                    memberInfo.birthCertificateFile
                      ? "Прикреплено: " + memberInfo.birthCertificateFile.name
                      : "Прикрепить скан/фотографию"
                  )
                }}
              </div>
            </div>
          </b-upload>
        </b-field>
      </div>
      <div class="column is-one-third">
        <b-field
          label="Медицинский полис"
          type="is-danger"
          :message="medicalPolicyFileMessage"
          class="fix-input-label"
        >
          <b-upload
            expanded
            v-model="memberInfo.medicalPolicyFile"
            class="file-label is-align-items-center file-panel"
            drag-drop
          >
            <div class="columns">
              <div
                class="column has-text-centered is-flex is-align-items-center is-justify-content-center pr-0 file-panel-icon"
              >
                <b-icon icon="upload" size="is-medium"> </b-icon>
              </div>
              <div
                class="column is-four-fifths pr-5 is-flex is-align-items-center has-text-centered is-justify-content-center"
              >
                {{
                  sliceFileUploadMessage(
                    memberInfo.medicalPolicyFile
                      ? "Прикреплено: " + memberInfo.medicalPolicyFile.name
                      : "Прикрепить скан/фотографию"
                  )
                }}
              </div>
            </div>
          </b-upload>
        </b-field>
      </div>
    </div>

    <h6 class="title is-size-6">Договор</h6>
    <div class="columns">
      <div class="column is-one-third">
        <b-button
          icon-left="file-download"
          type="is-link is-light"
          tag="a"
          href="/documents/Договор Лето побед 2024.pdf"
          target="_blank"
        >
          Скачать договор
        </b-button>
      </div>
      <div class="column">
        <b-field type="is-danger" :message="contractFileMessage">
          <b-upload
            expanded
            v-model="memberInfo.contractFile"
            class="file-label is-align-items-center file-panel"
            drag-drop
          >
            <div class="columns">
              <div
                class="column has-text-centered is-flex is-align-items-center is-justify-content-center pr-0 file-panel-icon"
              >
                <b-icon icon="upload" size="is-medium"> </b-icon>
              </div>
              <div
                class="column is-four-fifths pr-5 is-flex is-align-items-center has-text-centered is-justify-content-center"
              >
                {{
                  memberInfo.contractFile
                    ? "Прикреплено: " + memberInfo.contractFile.name
                    : "Прикрепить скан/фотографию"
                }}
              </div>
            </div>
          </b-upload>
        </b-field>
      </div>
    </div>

    <div class="has-text-centered">
      <button type="submit" class="button is-primary is-size-5">
        Отправить
      </button>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import { DialogProgrammatic as Dialog } from 'buefy'

export default {
  name: 'RegistrationForm',
  data () {
    return {
      memberInfo: {
        surname: '',
        firstname: '',
        lastname: '',
        age: null,
        address: '',
        school: '',
        parentFullName: '',
        phoneNumber: '',
        reservePhoneNumber: '',
        email: '',
        selectedFamilyStatus: null,
        selectedDirection: null,
        selectedAgeGroup: null,
        selectedShift: null,
        familyStatusFile: null,
        passportFile: null,
        birthCertificateFile: null,
        medicalPolicyFile: null,
        contractFile: null
      },
      familyStatuses: [],
      directions: [],
      ageGroups: [],
      shifts: [],
      limit: [],
      hostUrl: 'https://manager.cpvs.moscow/museumregistration',
      // hostUrl: 'http://127.0.0.1:8000/museumregistration',
      shiftMessage: '',
      shiftMessageType: false,
      ages: [
        { id: 7 },
        { id: 8 },
        { id: 9 },
        { id: 10 },
        { id: 11 },
        { id: 12 },
        { id: 13 }
      ]
    }
  },
  created () {
    axios.get(this.hostUrl + '/registration_limit').then((res) => {
      this.limit = res.data.limit

      function convertObjectToSelectOptions (objects) {
        return Object.entries(objects).map((item) => {
          if (typeof item[1] === 'object') {
            return {
              id: item[0],
              name: item[1].name,
              visible: item[1].visible
            }
          } else {
            return {
              id: item[0],
              name: item[1],
              visible: true
            }
          }
        })
      }

      this.familyStatuses = convertObjectToSelectOptions(res.data.familyStatuses)
      this.directions = convertObjectToSelectOptions(res.data.directions)
      this.ageGroups = convertObjectToSelectOptions(res.data.ageGroups)
      this.shifts = convertObjectToSelectOptions(res.data.shifts)

      this.$store.commit('setLoading', false)
    })
  },
  watch: {
    memberInfo: {
      handler () {
        if (this.limit.length === 0 || this.selectAgeGroups.length === 0 || this.selectDirections.length === 0) {
          this.shiftMessageType = true
          this.shiftMessage = 'Нет доступных смен'
          return
        }

        if (this.memberInfo.selectedDirection === null ||
          this.memberInfo.selectedAgeGroup === null ||
          this.memberInfo.selectedShift === null
        ) {
          this.shiftMessageType = false
          this.shiftMessage = ''
          return
        }
        const limit = parseInt(
          this.limit[this.memberInfo.selectedShift][this.memberInfo.selectedDirection][this.memberInfo.selectedAgeGroup]
        )

        if (limit <= 0) {
          this.shiftMessageType = true
          this.shiftMessage = 'Не осталось мест'
        } else if (limit > 0) {
          this.shiftMessageType = false
          this.shiftMessage = `Осталось ${limit} мест`
        } else {
          this.shiftMessageType = false
          this.shiftMessage = ''
        }
      },
      deep: true
    },
    selectAgeGroups: function () {
      if (this.selectAgeGroups.length === 1) {
        this.memberInfo.selectedAgeGroup = this.selectAgeGroups[0].id
      } else if (this.memberInfo.selectedAgeGroup !== null) {
        if (this.selectAgeGroups.find((item) => this.memberInfo.selectedAgeGroup === item.id) === undefined) {
          this.memberInfo.selectedAgeGroup = null
        }
      }
    },
    selectDirections: function () {
      if (this.selectDirections.length === 1) {
        this.memberInfo.selectedDirection = this.selectDirections[0].id
      } else if (this.memberInfo.selectedDirection !== null) {
        if (this.selectDirections.find((item) => this.memberInfo.selectedDirection === item.id) === undefined) {
          this.memberInfo.selectDirections = null
        }
      }
    }
  },
  computed: {
    disabledDirections: function () {
      return this.memberInfo.selectedShift === null || this.selectDirections.length === 1
    },
    disabledAgeGroup: function () {
      return this.memberInfo.selectedDirection === null || this.selectAgeGroups.length === 1
    },
    selectDirections: function () {
      if (this.memberInfo.selectedShift === null) {
        return this.directions
      }
      const directions = []

      this.directions.forEach(direction => {
        let limit = NaN
        if (this.memberInfo.selectedAgeGroup !== null) {
          if (!isNaN(parseInt(this.limit[this.memberInfo.selectedShift][direction.id][this.memberInfo.selectedAgeGroup]))) {
            limit = parseInt(this.limit[this.memberInfo.selectedShift][direction.id][this.memberInfo.selectedAgeGroup])
          }
        } else {
          this.ageGroups.forEach(age => {
            if (!isNaN(parseInt(this.limit[this.memberInfo.selectedShift][direction.id][age.id]))) {
              limit = parseInt(this.limit[this.memberInfo.selectedShift][direction.id][age.id])
            }
          })
        }

        if (!isNaN(limit)) {
          directions.push(direction)
        }
      })

      return directions
    },
    selectAgeGroups: function () {
      if (this.memberInfo.age === null) {
        if (this.memberInfo.selectedDirection !== null && this.memberInfo.selectedShift !== null) {
          return this.ageGroups.filter(age => !isNaN(parseInt(this.limit[this.memberInfo.selectedShift][this.memberInfo.selectedDirection][age.id])))
        } else {
          return this.ageGroups
        }
      }
      const ages = []

      if (this.memberInfo.age >= 7 && this.memberInfo.age <= 8) {
        ages.push(this.ageGroups[0])
      }
      if (this.memberInfo.age >= 8 && this.memberInfo.age <= 10) {
        ages.push(this.ageGroups[1])
      }
      if (this.memberInfo.age >= 11 && this.memberInfo.age <= 13) {
        ages.push(this.ageGroups[2])
      }
      return ages
    },
    familyStatusFileMessage: function () {
      if (parseInt(this.memberInfo.selectedFamilyStatus) === 0) return ''
      return this.memberInfo.familyStatusFile == null
        ? 'Загрузите документ подтверждения статуса семьи'
        : ''
    },
    passportFileMessage: function () {
      return this.memberInfo.passportFile == null
        ? 'Загрузите паспорт (первая страница и прописка)'
        : ''
    },
    birthCertificateFileMessage: function () {
      return this.memberInfo.birthCertificateFile == null
        ? 'Загрузите свидетельство о рождении'
        : ''
    },
    medicalPolicyFileMessage: function () {
      return this.memberInfo.medicalPolicyFile == null
        ? 'Загрузите медицинский полис'
        : ''
    },
    contractFileMessage: function () {
      return this.memberInfo.contractFile == null
        ? 'Прикрепите скан/фотографию договор'
        : ''
    }
  },
  methods: {
    sliceFileUploadMessage (message) {
      if (message.length > 40) {
        return message.slice(0, 37) + '...'
      }
      return message
    },
    sendRegistrationMemberInfo () {
      if (
        this.familyStatusFileMessage ||
        this.passportFileMessage ||
        this.birthCertificateFileMessage ||
        this.medicalPolicyFileMessage ||
        this.contractFileMessage
      ) {
        Dialog.alert({
          message: 'Вы указали не все документы!',
          type: 'is-danger'
        })
        return
      }

      if (
        this.memberInfo.selectedShift === null ||
        this.memberInfo.selectedDirection === null ||
        this.memberInfo.selectedAgeGroup === null
      ) {
        Dialog.alert({
          message: 'Выберите смену!',
          type: 'is-danger'
        })
        return
      }

      const limit = parseInt(
        this.limit[this.memberInfo.selectedShift][this.memberInfo.selectedDirection][this.memberInfo.selectedAgeGroup]
      )
      if (limit === 0) {
        Dialog.alert({
          message: 'В данной смене не осталось мест',
          type: 'is-danger'
        })
        return
      }

      this.$store.commit('setLoading', true)
      const formData = new FormData()
      for (const prop in this.memberInfo) {
        formData.append(prop, this.memberInfo[prop])
      }
      this.$buefy.notification.open({
        message: 'Идёт Регистрация. Подождите завершения!',
        type: 'is-success'
      })
      axios
        .post(this.hostUrl + '/save_registration_member', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          console.log(res.data)
          if (!res.data.status) {
            Dialog.alert({
              message: res.data.message,
              type: 'is-danger'
            })
          } else {
            this.$store.commit('setScreen', 'finish')
          }
          this.$store.commit('setLoading', false)
        })
        .catch(() => {
          this.$store.commit('setLoading', false)
          Dialog.alert({
            message: 'Ошибка при регистрации, попробуйте позже.',
            type: 'is-danger'
          })
        })
    }
  }
}
</script>

<style scoped lang="scss"></style>
