<template>
  <div id="app" class="has-background-info">
    <template>
      <div v-if="screen === 'form'" class="container is-max-desktop p-2 pt-6 pb-4">
        <registration-form/>
      </div>
      <div v-else-if="screen === 'error'" class="container is-max-desktop p-2 pt-6 pb-4">
        <div class="box has-text-centered">
          <h1 class="title">Ведутся технические работы</h1>
        </div>
      </div>
      <div v-else-if="screen === 'finish'" class="container is-max-desktop p-2 pt-6 pb-4">
        <div class="box has-text-centered">
          <h1 class="title">Регистрация прошла успешно!</h1>
          <p>Вся информация по смене в памятке для родителей!</p>
        </div>
      </div>
      <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
    </template>
  </div>
</template>

<script>
import RegistrationForm from '@/components/RegistrationForm'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    RegistrationForm
  },
  computed: {
    ...mapState(['isLoading', 'screen'])
  }
}
</script>
