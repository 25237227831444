import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: true,
    screen: 'form'
  },
  mutations: {
    setLoading (state, isLoading) {
      state.isLoading = isLoading
    },
    setScreen (state, screen) {
      state.screen = screen
    }
  },
  actions: {
  },
  modules: {
  }
})
